$sm-breackpoint: 769px;

/*-----------------------------------------------------------------------------------
    HERO
-----------------------------------------------------------------------------------*/
header.hero-img {
    overflow: hidden;
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 5em;
    padding-top: 150px;

    @media only screen and (min-width: 992px) {
        height: 100vh;
    }

    .hero-bg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        background: url("../img/hero-reporting.jpg") center center no-repeat;
        background-size: cover;
        animation-name: zoom;
        animation-duration: 30s;
        animation-direction: alternate;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
        will-change: transform;
        filter: blur(2px);
        opacity: 0.5;

        &:after {
            content: "";
            background: linear-gradient(
                #fff,
                #fff 14%,
                hsla(0, 0%, 100%, 0.1) 50%,
                hsla(0, 0%, 100%, 0.7) 80%,
                #fff
            );
            height: 100%;
            width: 100%;
            position: absolute;
            z-index: 1000;
            bottom: 0;
            display: block;
            top: auto;
        }
    }

    .intro-text {
        color: #000;

        .intro-heading {
            @include linear-gradient-text();
            font-weight: 700;
            font-size: 2.875em;

            @media only screen and (-ms-high-contrast: active),
                (-ms-high-contrast: none) {
                @include linear-gradient-hide();
            }

            @media only screen and (min-width: 768px) {
                font-size: 5em;
                margin: 0 auto;
                text-transform: uppercase;
            }

            @media only screen and (min-width: 992px) {
                font-size: 5em;
                margin: 0 auto;
                text-transform: uppercase;
            }

            &.small-heading {
                font-size: 3em;
                line-height: 1.2;

                @media only screen and (min-width: 960px) {
                    font-size: 3.25em;
                }
            }
        }

        .intro-lead-in {
            font-size: 1.875em;
            max-width: 850px;
            text-transform: none;

            @media only screen and (min-width: 768px) {
                margin: 1.25em 0;
                font-weight: 400;
                font-size: 2em;
                line-height: 1.3;
            }
        }

        h4.intro-lead-in {
            text-transform: uppercase;
        }

        .text-small {
            font-size: 1em;
            margin: 1em auto;
        }

        &.text-center {
            @media only screen and (min-width: 768px) {
                text-align: left;
            }
        }
    }

    .img-offside {
        position: relative;
        left: -31px;
        -webkit-filter: drop-shadow(0 7px 21px rgba(0,0,0,.04));
        filter: drop-shadow(0 7px 21px rgba(0,0,0,.04));
        max-width: 130%;
        width: 130%;

        @media only screen and (min-width: 768px) {
            max-width: 100%;
            width: 100%;
        }
    }

    .img-offside-2 {
        position: relative;
        right: 10%;
        // -webkit-filter: drop-shadow(0 7px 21px rgba(0,0,0,.04));
        // filter: drop-shadow(0 7px 21px rgba(0,0,0,.04));
        max-width: 130%;
        width: 130%;

        @media only screen and (min-width: 768px) {
            right: -60px;
            max-width: 100%;
            width: 100%;
            transform: scale(1.4);
        }
    }
    .row-eq-height {
        > div {
            align-content: center;
        }
    }
}

// @keyframes zoom {
//     from {
//         transform: scale(1);
//     }

//     to {
//         transform: scale(1.3);
//     }
// }

.index-reporting {
    section {
        .card {
            h3 {
                text-transform: none;
                font-weight: 500;
                margin-bottom: 40px;
            }

            p,
            ul {
                font-size: 1.125em;
            }

            ul {
                padding-inline-start: 20px;
            }

            .card__meta {
                letter-spacing: 0.1em;
            }

            .card__padding {
                padding: 2.5em 2em;
            }

            .card__action {
                margin: 0 auto;
                text-align: center;

                a {
                    display: inline;
                }
            }
        }

        .colio-active-item .card {
            background: $brand-primary;
            color: #fff;
            transition: background 1s ease-in-out;
        }
    }

    #trendmonitor-leistungen {
        .card {
            margin: 2em 0 4em;
            background-color: transparent;

            h3 {
                margin-bottom: 20px;
            }
        }
    }
}

.card__image.border-tlr-radius svg path {
    fill: #000000;
}

// .expert-image.big {
//     height: 450px;
// }

#gl {
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    position: absolute;
}

canvas {
    display: block;
}

/*-----------------------------------------------------------------------------------
    Scrollanimation Styles
-----------------------------------------------------------------------------------*/

.sm-cta-svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    svg {
        path {
            fill: rgba(105, 176, 207, 0.1);
        }
    }
}

.section {
    transition: opacity 0.6s ease-in-out, transform 0.6s ease-in-out;
    transform: matrix(1, 0, 0, 1, 0, 50);
    opacity: 0;

    @media only screen and (min-width: $sm-breackpoint) {
        padding: 70px 0;
    }

    > div {
        z-index: 1;
    }
}

.slideUpAnimated {
    transition: opacity 0.6s ease-in-out, transform 0.6s ease-in-out;
    transform: matrix(1, 0, 0, 1, 0, 50);
    opacity: 0;
}

.bg-shape {
    position: fixed;
    top: 50%;
    left: 0;
    width: 150%;
    height: 100%;
    background: url("../img/bg/background-bubble--left.svg") left top no-repeat;
    background-size: 50%;
    transform: translateY(-40%);
    z-index: -1;
}

.bg-shape-2 {
    position: fixed;
    top: 50%;
    right: 0;
    width: 120%;
    height: 100%;
    background: url("../img/bg/background-bubble--right.svg") right top
        no-repeat;
    background-size: 50%;
    transform: translateY(-40%);
    z-index: -1;
}

.sm-show {
    opacity: 1;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

.sm-hide {
    display: none;
}

.sm-opacity {
    transition: opacity 0.3s ease-in-out;
    opacity: 0;

    @media only screen and (min-width: $sm-breackpoint) {
    }
}

.sm-opacity-2 {
    transition: opacity 1s ease-in-out;
    opacity: 0;

    @media only screen and (min-width: $sm-breackpoint) {
    }
}

.sm-fade-in {
    opacity: 1;
}

/* Colio - Basic Styles
================================================== */

.colio {
    position: relative;
    height: 0;
    clear: both;
    overflow: hidden;

    &.colio-expanded {
        overflow: initial;
    }
}

.colio-container {
    padding: 56px 56px 100px;
    height: 100%;
    box-sizing: border-box;
    background: #fff;
}

.colio-content {
    display: none;
    overflow: hidden;
}

.colio-close,
.colio-prev,
.colio-next {
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.colio-no-prev,
.colio-no-next {
    cursor: default;
    opacity: 0.2;
}

/* navigation, close */

.colio-close {
    position: absolute;
    top: 10px;
    right: 10px;

    &:hover {
        &:before {
            display: none;
        }
    }

    span {
        display: block;
        text-indent: -9999px;
        $size: 50px;
        height: $size;
        width: $size;
        position: relative;
        box-sizing: border-box;
        line-height: $size;
        display: inline-block;

        &:before,
        &:after {
            $width: 30px;
            $height: 1px;
            transform: rotate(-45deg);
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -1px;
            margin-left: -15px;
            display: block;
            height: $height;
            width: $width;
            background-color: $brand-primary;
            transition: all 0.25s ease-out;
        }

        &:after {
            transform: rotate(-135deg);
        }

        &:hover {
            &:before,
            &:after {
                transform: rotate(0deg);
            }
        }
    }
}

/* .colio-navigation {
    // position: absolute;
    // top: 10px;
    // left: 40px;
} */

.colio-navigation a {
    margin-right: 5px;
    position: absolute;
    bottom: 20px;
    background: #fff;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    border: 1px solid #eee;
    transition: border-color 0.3s ease-in-out;

    &:hover {
        border-color: $brand-primary;

        &.colio-no-prev,
        &.colio-no-next {
            border-color: #eee;
        }
    }

    span {
        display: block;
        text-indent: -9999px;
    }

    &.colio-prev,
    &.colio-no-prev {
        right: 80px;

        &:before {
            content: "";
            background: url(../img/arrow-pointing-left.svg) center center
                no-repeat;
            height: 20px;
            width: 20px;
            left: 12px;
            top: 14px;
        }
    }

    &.colio-next,
    &.colio-no-next {
        right: 20px;

        &:before {
            content: "";
            background: url(../img/arrow-pointing-right.svg) center center
                no-repeat;
            height: 20px;
            width: 20px;
            left: 15px;
            top: 14px;
        }
    }
}

/* placement */

.colio-placement-inside,
.colio-placement-before {
    margin-bottom: 20px;
}

.colio-placement-after {
    margin-top: 20px;
    margin-bottom: 20px;
    @include box-shadow;
}

.logo_grid {
    list-style-type: none;
    margin: 0 auto;
    padding: 0;
    text-align: left;
    max-width: 25em;

    li {
        display: flex;
        align-items: center;
        text-align: center;
        position: relative;
        z-index: 1;
        padding: 1.875em 2.5em;
        margin-bottom: 1em;

        img {
            display: block;
            position: relative;
            margin: 0 auto;
            transition: all 0.5s ease;
        }
    }

    @media only screen and (min-width: 40em) {
        max-width: 50em;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        li {
            width: 47.5%;
        }
    }

    @media only screen and (min-width: 80em) {
        max-width: 100%;

        li {
            width: 18%;
            padding: 1.5em 0 1.875em;
            margin-bottom: 2.5em;

            .testimonial_contain {
                top: 0;
                left: 50%;
                width: 200%;
                transform: translateX(-50%);
            }

            &:nth-child(1),
            &:nth-child(6) {
                img {
                    margin: 0;
                }

                .testimonial_contain {
                    text-align: left;
                    top: 0;
                    left: -2.5em;
                    transform: none;
                }
            }

            &:nth-child(5),
            &:nth-child(10) {
                img {
                    margin: 0 0 0 auto;
                }

                .testimonial_contain {
                    text-align: left;
                    top: 0;
                    left: auto;
                    right: -2.5em;
                    transform: none;
                }
            }
        }
    }
}

.panel {
    &.panel-default {
        border: none;
        border-radius: 0;
        margin-bottom: 25px;
    }

    .panel-body {
        background: #fff;
        padding: 2em;

        p {
            font-size: 1.125em;
        }
    }
}

.panel .panel-heading {
    border-radius: 0;
    padding: 0;
}

.panel .panel-heading a {
    box-shadow: 0 0 40px 0 #e3edfc;
    display: block;
    background: #fff;
    color: #333;
    padding: 1.5em 4.5em 1.5em 1.5em;
    border-radius: 0;
    text-decoration: none;
    font-size: 20px;
    text-transform: none;
    font-weight: 500;

    &:before,
    &:after {
        width: 16px;
        height: 1px;
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        right: 10px;
        left: auto;
        margin-top: -1px;
        background-color: #a50034;
        transition: all 0.5s;

        @media only screen and (min-width: 480px) {
            width: 30px;
            top: 50%;
            right: 30px;
            height: 2px;
        }
    }

    &:after {
        width: 1px;
        height: 16px;
        right: 17px;
        margin-top: -8px;

        @media only screen and (min-width: 480px) {
            width: 2px;
            height: 30px;
            right: 45px;
            margin-top: -15px;
        }
    }

    &[aria-expanded="true"] {
        &:before,
        &:after {
            transform: rotate(-90deg);
        }

        &:before {
            opacity: 0;
        }
    }
}

#accordion .highlight a {
    background: #1fb5ac;
    /* change colour of selected bar here if needed */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    color: white;
    transition: background 2s ease;
}

/* Photostack – Print-Berichte
================================================== */

.photostack {
    padding-top: 20px;

    figure {
        padding: 1em;
        width: 640px;
        height: auto;

        figcaption {
            h2 {
                margin: 20px 0 0;
                color: #000;
                font-size: 1em;
            }
            p {
                font-size: 1em;
            }
        }
    }
    nav {
        bottom: 16px;
    }
}

.photostack-img {
    width: 100%;
    height: auto;
}

.js .photostack {
    height: 640px;
}

/* Angebote
================================================== */

.card {
    &.report-type {
        position: relative;

        &.report-gb {
            background: url("../img/teaser/teaser-gb.jpg") no-repeat center
                center;
            background-size: cover;
        }
        &.report-jb {
            background: url("../img/teaser/teaser-jb.jpg") no-repeat center
                center;
            background-size: cover;
        }
        &.report-nb {
            background: url("../img/teaser/teaser-nb.jp") no-repeat center
                center;
            background-size: cover;
        }
        &.report-ub {
            background: url("../img/teaser/teaser-ub.jpg") no-repeat center
                center;
            background-size: cover;
        }
        &.report-sr {
            background: url("../img/teaser/teaser-sr.jp") no-repeat center
                center;
            background-size: cover;
        }

        &:after {
            content: "";
            display: inline-block;
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.5);
            z-index: 0;
        }
        .card__image.border-tlr-radius {
            box-shadow: 0px 6px 40px rgba(0, 0, 0, 0.08);
            overflow: visible;
            img {
                border-radius: 50%;
            }
            svg {
                width: 100%;
                box-shadow: 0px 6px 40px rgba(0, 0, 0, 0.08);
            }
        }
        .card__image,
        .card__content,
        .card__action {
            z-index: 2;
        }

        h3 {
            color: #fff;
        }
        .card__action {
            overflow: visible;
            a {
                display: inline-block;
                width: 100%;
                background: rgba(255, 255, 255, 0.7);
                padding: 0.5em 1em;
                border-radius: 0.5em;
            }
        }
    }
}

.white-popup {
    padding: 0 10px;
}

/*-----------------------------------------------------------------------------------
    Angebot
-----------------------------------------------------------------------------------*/
.bottom-info {
    background: linear-gradient(194.06deg, #e60048 18.05%, #a50034 94.36%);
    box-shadow: 2px 5px 10px rgba(165, 0, 52, 0.1);
}

/*-----------------------------------------------------------------------------------
    Referenzen
-----------------------------------------------------------------------------------*/
#reporting-referenzen {
    .content {
        text-align: center;

        @media only screen and (min-width: 768px) {
            text-align: left;
        }

        h5 {
            display: inline-block;
            padding: 10px 16px;
            background: #edf0f2;
            border-radius: 12px;
            font-weight: 600;
            letter-spacing: 0.03em;
        }
    }
}

/*-----------------------------------------------------------------------------------
    Angebot
-----------------------------------------------------------------------------------*/
.bottom-info {
    background: linear-gradient(194.06deg, #E60048 18.05%, #A50034 94.36%);
    box-shadow: 2px 5px 10px rgba(165, 0, 52, 0.1);


    @media only screen and (min-width: 992px) {
        display: flex;
        flex: wrap;
        align-items: center;
        justify-content: space-around;
        padding: 4em 5em 4em 4em;
        max-width: 1066px;
        margin: 0 auto;
    }

    .h3--offer {
        font-weight: 400;
        font-size: 2.25em;
        line-height: 1.3;
        margin: 0 0 1.25em 0;

        @media only screen and (min-width: 992px) {
            font-weight: 400;
            font-size: 35px;
            line-height: 50px;
            text-align: left;
            flex: 0 1 66%;
            margin: 0;
        }
    }
    .h3--offer-main {
        font-weight: 400;
        font-size: 2.25em;
        line-height: 1.3;
        margin: 0 0 1.25em 0;
        text-transform: none;

        @media only screen and (min-width: 992px) {
            font-weight: 400;
            font-size: 42px;
            line-height: 62px;
            text-align: left;
            flex: 0 1 66%;
            margin: 0;
        }
    }

    &.bottom-info__horizontal {
        @media only screen and (min-width: 992px) {
            display: block;
            padding: 4em 5em 4em 4em;
            max-width: 1066px;
            margin: 0 auto;
            text-align: center;
        }

        .h3--offer {
            padding: 0; 
            margin-bottom: 2em!important;
            @media only screen and (min-width: 992px) {
                text-align: center;
            }
        }
        .h3--offer-main {
            padding: 0; 
            margin-bottom: 2em;
            @media only screen and (min-width: 992px) {
                text-align: center; 
            }
        }
    }
}


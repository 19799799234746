.SiteHeaderLogo {
    position: relative;
    display: inline-flex;
    flex-flow: row nowrap;
    overflow: hidden;
    float: left;
}

.SiteHeaderLogo-icon {
    margin-right: 5px;
    width: 10px;
    height: 10px;
    transition: all .35s;

    @media only screen and (min-width:962px) {
        width: 15px;
        height: 15px;
    }

}

.SiteHeader--slim .SiteHeaderLogo-icon {
    margin-right: 0;
    width: 50px;
    height: 50px;
}

.SiteHeaderLogo-text {
    width: 95px;
    height: 24px;
    transition: all .35s;
    transform-origin: 0 50%;

    @media only screen and (min-width:962px) {
        width: 135px;
        height: 34px;
    }
}

.SiteHeader--slim .SiteHeaderLogo-text {
    width: 0;
    opacity: 0;
    transform: scale(0) translate3d(-50px, 0, 0);
}

.SiteHeaderLogo-image {
    display: block;
    padding-top: 2px;
    padding-bottom: 2px;
    transition: padding .35s;
}

.SiteHeader--slim .SiteHeaderLogo-image {
    padding-top: 10px;
    padding-bottom: 9px;
}

.SiteHeaderToggle {
    display: block;
    position: relative;
    float: right;
    margin: 0;
    padding: 0;
    height: 35px;
    border: 0;
    line-height: 1;
    background: none;
    color: #000;
    font-size: 1.5rem;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
    transition: height .35s;
}

.SiteHeader--slim .SiteHeaderToggle {
    height: 50px;
}

.SiteHeaderToggle-text {
    display: none;
}

.SiteHeaderToggle-icon {
    display: block;
    position: relative;
    top: 2px;
    float: right;
    margin: 8px 5px;
    cursor: pointer;
}

.SiteHeaderToggle-icon,
.SiteHeaderToggle-icon:after,
.SiteHeaderToggle-icon:before {
    width: 30px;
    height: 2px;
    background-color: #000;
    transition: all .1s;
}

.SiteHeaderToggle-icon:after,
.SiteHeaderToggle-icon:before {
    content: "";
    position: absolute;
    left: 0;
}

.SiteHeaderToggle-icon:before {
    top: -8px;
}

.SiteHeaderToggle-icon:after {
    bottom: -8px;
}

.SiteHeader--open .SiteHeaderToggle-icon {
    background-color: transparent;
    transform: rotate(90deg);
}

.SiteHeader--open .SiteHeaderToggle-icon:before {
    top: 0;
    transform: rotate(45deg);
}

.SiteHeader--open .SiteHeaderToggle-icon:after {
    bottom: 0;
    transform: rotate(-45deg);
}

.SiteHeader {
    z-index:1000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}

.noscroll .SiteHeader {
    overflow: auto!important;
    bottom: 0!important;
}

.SiteHeader ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.SiteHeader-inner {
    padding: 43px 20px;
    background-color: transparent;
    transition: padding .35s;
}

.SiteHeader-inner:after {
    content: "";
    display: block;
    clear: both;
}

.SiteHeader--slim .SiteHeader-inner {
    @include box-shadow;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #fff;
}

.SiteHeaderNavigation {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    position: relative;
}

.SiteHeaderNavigation.is-transitioning {
    overflow: hidden!important;
}

.SiteHeaderNavigation.is-present {
    background-color: #fff;
}

.SiteHeaderNavigation-circle {
    position: absolute;
    top: -125px;
    right: 0;
    border-radius: 100%;
    background-color: #fff;
    transform: scale(0);
}

.SiteHeaderNavigation-menu a {
    color: inherit;
    text-decoration: none;
}

.SiteHeaderNavigation-menu .menu-item {
    font-size: 1em;
    font-weight: 600;
    text-transform: uppercase;
}

.SiteHeaderNavigation-menu .menu-item>a {
    display: inline-block;
    margin: 0 auto 8px;
    padding: 10px 0;
    line-height: 1;
}

.SiteHeaderNavigation-menu .menu-item-has-children>a {
    display: none;
}

.SiteHeaderNavigation-menu .menu-item a {
    position: relative;
    text-decoration: none!important;
    &:hover,
    &:focus,
    &:active,
    .active & {
        color: $body-color;
    }
}


.SiteHeaderNavigation-menu .menu-item a:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    height: 2px;
    background-color: #cdbb88;
    transition: width .3s;
    right: 0;
    width: 0;
}

.SiteHeaderNavigation-menu .menu-item a.animated:before,
.SiteHeaderNavigation-menu .menu-item a:hover:before,
.SiteHeaderNavigation-menu .menu-item.active a:before {
    left: 0;
    right: auto;
    width: 100%;
}

.SiteHeaderNavigation-menu .current-menu-ancestor>a,
.SiteHeaderNavigation-menu .current-menu-item a,
.SiteHeaderNavigation-menu .current-page-ancestor a {
    position: relative;
    text-decoration: none!important;
}

.SiteHeaderNavigation-menu .current-menu-ancestor>a:before,
.SiteHeaderNavigation-menu .current-menu-item a:before,
.SiteHeaderNavigation-menu .current-page-ancestor a:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    height: 2px;
    background-color: #cdbb88;
    transition: width .3s;
    left: 0;
    width: 100%;
}

.SiteHeaderNavigation-menu .current-menu-ancestor>a.animated:before,
.SiteHeaderNavigation-menu .current-menu-ancestor>a:hover:before,
.SiteHeaderNavigation-menu .current-menu-item a.animated:before,
.SiteHeaderNavigation-menu .current-menu-item a:hover:before,
.SiteHeaderNavigation-menu .current-page-ancestor a.animated:before,
.SiteHeaderNavigation-menu .current-page-ancestor a:hover:before {
    left: auto;
    right: 0;
    width: 0;
}

.SiteHeaderNavigation-menu .search-icon>a {
    overflow: hidden;
    width: 50px;
    background: transparent url(assets/img/icons/search.svg) no-repeat 50%/28px;
    text-indent: -9999px;
}

.SiteHeaderNavigation-cta {
    z-index: 1;
    padding: 20px 20px 45px;
}

.SiteHeaderNavigation-cta .Button {
    font-size: 1rem;
    text-transform: uppercase;
}

.SiteHeaderNavigation-cta,
.SiteHeaderNavigation-menu {
    position: absolute;
    left: -9999px;
    opacity: 0;
    text-align: center;

    &:focus {
        outline: none;
    }
}

.SiteHeaderNavigation-cta.is-transitioning,
.SiteHeaderNavigation-menu.is-transitioning {
    position: static;
    left: auto;
}

.SiteHeaderNavigation-cta.is-present,
.SiteHeaderNavigation-menu.is-present {
    position: relative;
    left: auto;
}

@media only screen and (min-width:380px) {
    .SiteHeaderToggle-text {
        display: inline;
        position: relative;
        top: 4px;
        margin-right: 10px;
        font-size: 1em;
    }
}
@media only screen and (min-width:1330px) {
    .SiteHeaderToggle {
        display: none!important;
    }
    .SiteHeader-inner {
        padding-left: 50px;
        padding-right: 50px;
    }
    .SiteHeaderNavigation {
        flex-flow: row nowrap;
    }
    .SiteHeaderNavigation-menu {
        //left: 310px!important;
        //top: -76px;
        padding: 0!important;
        opacity: 1!important;
        transition: top .35s!important;
        position: relative;
        top: -80px;
        left: inherit;
    }
    .SiteHeader--slim .SiteHeaderNavigation-menu {
        top: -51px;
    }
    .SiteHeaderNavigation-menu .menu-item {
        float: left;
        margin: 0 20px;
        font-size: 14px;
    }
    .SiteHeaderNavigation-menu .menu-item:first-child,
    .SiteHeaderNavigation-menu .menu-item.menu-first-child {
        margin-left: 0;
    }
    .SiteHeaderNavigation-menu .menu-item:last-child {
        margin-right: 0;
    }
    .SiteHeaderNavigation-menu .menu-item>a {
        margin: 0;
    }
    .SiteHeaderNavigation-menu .menu-item-has-children>a {
        display: block;
    }
    .SiteHeaderNavigation-menu .menu-item-has-children>a:after {
        content: "";
        display: inline-block;
        margin-left: 4px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 4px 3.5px 0;
        border-color: #000 transparent transparent;
        vertical-align: middle;
    }
    .SiteHeaderNavigation-menu .menu-item-has-children:hover>a {
        position: relative;
        z-index: 2;
    }
    .SiteHeaderNavigation-menu .menu-item-has-children:hover>a:after {
        border-width: 0 3.5px 4px;
        border-color: transparent transparent #000;
    }
    .SiteHeaderNavigation-menu .menu-item-has-children>.sub-menu {
        position: absolute;
        top: -20px;
        left: -9999px;
        margin-left: -30px;
        padding: 70px 30px 25px;
        background-color: #fff;
        text-align: left;
        box-shadow: 0 0 15px rgba(0, 0, 0, .05);
    }
    .SiteHeaderNavigation-menu .menu-item-has-children:hover>.sub-menu {
        z-index: 1;
        left: auto;
    }
    .SiteHeaderNavigation-menu .menu-item-has-children>.sub-menu>.menu-item {
        float: none;
        margin: 0;
        font-weight: 300;
        text-transform: none;
    }
    .SiteHeaderNavigation-cta {
        position: fixed!important;
        left: auto!important;
        top: 37px;
        right: 50px;
        padding: 0;
        opacity: 1!important;
        transition: top .35s;
    }
    .SiteHeader--slim .SiteHeaderNavigation-cta {
        top: 13px;
    }
}
.flipping-card-wrapper {
    width: 100%;
    margin: 0 auto;

    .cols {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .col {
        width: calc(100% - 2rem);
        margin: 1rem;
        cursor: pointer;

        @media screen and (min-width: 768px) {
            width: calc(50% - 2rem);
        }

        @media screen and (min-width: 960px) {
            width: calc(33.333333% - 2rem);
        }

        @media screen and (min-width: 1440px) {
            width: calc(20% - 2rem);
        }

    }

    .flipping-card-content {
        transform-style: preserve-3d;
        perspective: 1000px;

        .front,
        .back {
            @include box-shadow;
            background-size: cover;
            // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);
            background-position: center;
            transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            text-align: center;
            min-height: 380px;
            height: auto;
            color: #fff;
            font-size: 1.5rem;
            overflow: hidden;

            @media screen and (min-width: 768px) {
                min-height: 400px;
            }
    
            @media screen and (min-width: 960px) {
                min-height: 400px;
            }
    
            @media screen and (min-width: 1440px) {
                min-height: 450px;
            }
        }

        .back {
            background: #cedce7;
            background: linear-gradient(45deg,  #525e68 0%,#596a72 100%);
            //background: linear-gradient(25deg, #d6c89e 25%, #cdbb88 75%);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;

            p {
                font-size: 1.25em;
                text-align: left;
                line-height: 1.5;
            }
        }

        .front {
            &:after {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                width: 100%;
                height: 100%;
                content: "";
                display: block;
                opacity: 0.4;
                background-color: #000;
                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
            }

            .inner {
                h3 {
                    font-size: 2rem;
                    margin-bottom: 2rem;
                    position: relative;
                    letter-spacing: .04em;

                    &:after {
                        content: "";
                        width: 4rem;
                        height: 2px;
                        position: absolute;
                        background: #c6d4df;
                        display: block;
                        left: 0;
                        right: 0;
                        margin: 0 auto;
                        bottom: -0.75rem;
                    }
                }
                span {
                    color: rgba(255, 255, 255, 0.7);
                    font-weight: 300;
                }
            }
        }
        .inner {
            transform: translateY(-50%) translateZ(60px) scale(0.94);
            top: 50%;
            position: absolute;
            left: 0;
            width: 100%;
            padding: 2rem;
            box-sizing: border-box;
            outline: 1px solid transparent;
            -webkit-perspective: inherit;
            perspective: inherit;
            z-index: 2;
        }

        &:hover {
            .front,
            .back {
                -webkit-transition: -webkit-transform 0.7s
                    cubic-bezier(0.4, 0.2, 0.2, 1);
                transition: -webkit-transform 0.7s
                    cubic-bezier(0.4, 0.2, 0.2, 1);
                -o-transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
                transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
                transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1),
                    -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
            }
        }
    }

    .flipping-card-content .back {
        -webkit-transform: rotateY(180deg);
        transform: rotateY(180deg);
        -webkit-transform-style: preserve-3d;
        transform-style: preserve-3d;
    }

    .flipping-card-content .front {
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg);
        -webkit-transform-style: preserve-3d;
        transform-style: preserve-3d;
    }

    .flipping-card-content:hover .back {
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg);
        -webkit-transform-style: preserve-3d;
        transform-style: preserve-3d;
    }

    .flipping-card-content:hover .front {
        -webkit-transform: rotateY(-180deg);
        transform: rotateY(-180deg);
        -webkit-transform-style: preserve-3d;
        transform-style: preserve-3d;
    }
}

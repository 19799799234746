@mixin perspective-right {
    transform: perspective(1000px) rotateY(-15deg);
    perspective-origin: 0 0;
    transform-origin: bottom right;
}
@mixin perspective-left {
    transform: perspective(1000px) rotateY(15deg);
    perspective-origin: 0 0;
    transform-origin: bottom left;
}
@mixin perspective-center {
    transform: perspective(1000px) rotateY(0deg);
    perspective-origin: 0 0;
    transform-origin: top center;
}
@mixin transition($duration, $timing, $property) {
    transition-duration: $duration;
    transition-timing-function: $timing;
    transition-property: $property;
}

@mixin MQ($canvas) {
    @if $canvas == S {
        @media only screen and (min-width: $S) {
            @content;
        }
    } @else if $canvas == M {
        @media only screen and (min-width: $M) {
            @content;
        }
    } @else if $canvas == L {
        @media only screen and (min-width: $L) {
            @content;
        }
    }
}

// breakpoints
$S: 600px;
$M: 768px;
$L: 1170px;

:root {
    --screen-width: 175px;
    --screen-height: 300px;
    --screen-width-big: 350px;
    --screen-height-big: 500px;
}

.screen-wrapper {
    @include clearfix;
    position: relative;
    width: var(--screen-width);
    height: var(--screen-height);
    margin-bottom: 4em;

    @include MQ(M) {
        width: var(--screen-width-big);
        height: var(--screen-height-big);
        margin-bottom: 0;
    }

    .screenshot {
        text-align: center;
        width: var(--screen-width);
        height: var(--screen-height);
        background-repeat: no-repeat;
        position: relative;
        background-position: 50% 0%;
        border-radius: 1px;
        z-index: 1;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.1);

        @include MQ(M) {
            width: var(--screen-width-big);
            height: var(--screen-height-big);
        }
    }

    .mask {
        height: calc(100% + 60px);
        width: calc(100% + 60px);
        position: absolute;
        top: -30px;
        left: -30px;
        background: #fff;
        border-radius: 23px;
        z-index: -1;
        border: 5px solid #fff;
        @include box-shadow;

        @include MQ(M) {
            border-radius: 23px 38px 38px 23px;
            border: 10px solid #fff;
        }
    }

    &.perspective-right {
        .screenshot,
        .mask {
            @include MQ(M) {
                @include perspective-right();
            }
        }
    }

    &.perspective-left {
        .screenshot,
        .mask {
            @include MQ(M) {
                @include perspective-left();
            }
        }
    }

    .transition-1 {
        @include transition(6s, ease-in-out, all);
    }
    .transition-2 {
        @include transition(4s, ease-in-out, all);
    }
    .transition-3 {
        @include transition(3s, ease-in-out, all);
    }

    &:hover {
        .screenshot {
            background-position: 50% 100%;
        }
        .mask {
            border-radius: 38px 23px 23px 38px;
        }

        &.perspective-right {
            .screenshot,
            .mask {
                @include MQ(M) {
                    @include perspective-center();
                }
            }
        }

        &.perspective-left {
            .screenshot,
            .mask {
                @include MQ(M) {
                    @include perspective-center();
                }
            }
        }
    }
}

#screenshot-suewag {
    background-image: url("../img/referenzen/Suewag-Online-GB-Onepager.png");
    background-size: cover;
}
#screenshot-swl {
    background-image: url("../img/referenzen/SWL-Online-GB-Onepager.png");
    background-size: cover;
}
#screenshot-swh {
    background-image: url("../img/referenzen/SWH-Online-GB-E-Paper.png");
    background-size: cover;
}
#screenshot-lvv {
    background-image: url("../img/referenzen/LVV-Nachhaltigkeitsbericht-2021.png");
    background-size: cover;
}
#screenshot-evd {
    background-image: url("../img/referenzen/EVD-Nachhaltigkeitsbericht-2021.png");
    background-size: cover;
}
